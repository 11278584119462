module.exports = {
	"processor_callbacks": {
		"group_title": "Processor callbacks",
		"retry_message": "Callback has been resent",
		"dispatch": {
			"title": "Callbacks dispatch",
			"callback_type": "Callback type",
			"callback_body": "Callback",
			"successfully_sent": "Callback successfully sent"
		},
		"list": {
			"title": "Callback list",
			"failure_message_checkbox": "Empty failure message only (null)",
			"callback_body_checkbox": "Empty callback body only (null)"
		},
		"details": {
			"page_title": "Callback {id}",
			"title": "Callback details",
			"retry": "Retry callback",
			"to_list": "To callback list"
		},
		"callback": {
			"id": "ID",
			"processor": "Processor",
			"status": "Status",
			"failure_message": "Failure message",
			"callback_body": "Body",
			"created_at": "Created at",
			"updated_at": "Updated at"
		},
		"status": {
			"initial": "Initial",
			"queued": "Queued",
			"failed": "Failed",
			"success": "Success"
		}
	}
};