import Settings from "$api/settings"
import AssetsManager from "$services/AssetsManager"

Settings.manifest().then(manifest => {
  const { bundle, ...others } = manifest

  const bundleUrls = Object.values(bundle)
  const otherAssetsUrls = Object.values(others).flatMap(asset => Object.values(asset))

  const bundleAssetsManager = AssetsManager.make(...bundleUrls)
  const othersAssetsManager = AssetsManager.make(...otherAssetsUrls)

  return othersAssetsManager
    .load()
    .then(() => {
      bundleAssetsManager.load()
    })
})
