module.exports = {
	"widget": {
		"title": "Тестовый виджет",
		"new": "Новый виджет",
		"customer_country": "Страна игрока",
		"new_customer": "Новый игрок",
		"only_base_checks": "Пропустить детальную проверку (VIP и пр.)",
		"token_country": "Страна по IP",
		"full": "Полный",
		"mobile_app": "Мобильное приложение",
		"disabled_limits": "Отключить лимиты",
		"one_click_common": "1-click вне игры",
		"one_click_mobile_app": "1-click мобильное приложение",
		"one_click_mobile_app_common": "1-click мобильное приложение вне игры",
		"common": "Обычный",
		"game": "Игровой",
		"vip_page": "VIP страница",
		"split_cards": "Разделять карты",
		"initialization_error": "Возникла ошибка при загрузке виджета",
		"show_disabled_systems": "Показывать отключенные системы",
		"enable_channel_selection": "Выбор канала",
		"tabs": {
			"ui": "Виджет",
			"deposit_systems": "ПС депозит",
			"withdraw_systems": "ПС выплата",
			"project_systems": "ПС для проекта"
		},
		"systems_table": {
			"system": "Платежная система",
			"processor": "Процессинг",
			"channel_code": "Канал",
			"last_used_index": "Индекс исп.",
			"last_used_index_tooltip": "Индекс последнего использования платежной системы пользователем. Чем меньше число, тем позже была в последний раз использована система и выше позиция в списке.",
			"priority": "Приоритет",
			"priority_tooltip": "Можно поменять в правилах настроек систем. Чем больше число, тем выше позиция в списке.",
			"alt_priority": "Приоритет альта",
			"alt_priority_tooltip": "Первое число - 4 значит недавний успех через альт, 3 значит первый депозит, 2 значит альт отошел от фейла, 1 значит недавний фейл и 0 значит проблемный альт. Второе число - приоритет альта, настроенный через правила. Первое число приоритетнее чем второе. Большие числа значат больший приоритет.",
			"rating": "Рейтинг в стране",
			"rating_tooltip": "Рейтинг означает число игроков, которые пользовались системой в данной стране. Чем больше число, тем выше позиция в списке.",
			"priority_reason": "Причина приоритета",
			"status": "Статус",
			"disable_reason": "Причина выключения",
			"default_priority_reason": "По умолчанию",
			"disable_reason_type": {
				"processor_context_invalid": "Невалидный контекст процессинга",
				"processor_account_invalid": "Невалидный аккаунт процессинга",
				"payments_disabled": "Платежи выключены через действие ({id})",
				"system_is_hidden": "Система скрыта через действие ({id})",
				"disabled_to_customer": "Выключена для пользователя"
			},
			"limits": "Лимиты",
			"limits_tooltip": "В валюте токена",
			"limits_reason": "Причина лимитов",
			"limits_reason_type": {
				"processor_context": "Контекст процессинга",
				"action": "Действие {id}"
			}
		}
	}
};