module.exports = {
	"free_deposit_limits": {
		"title": "Лимиты фридепов",
		"customer": "Игрок",
		"email": "Email",
		"daily_amount": "Дневной остаток",
		"weekly_amount": "Недельный остаток",
		"monthly_amount": "Месячный остаток",
		"daily_limit": "Дневной лимит",
		"weekly_limit": "Недельный лимит",
		"monthly_limit": "Месячный лимит",
		"currency": "Валюта",
		"enabled": "Включено",
		"created_at": "Дата создания",
		"actions": "Действия",
		"created_success": "Лимит успешно создан",
		"updated_success": "Лимит успешно обновлен",
		"limit_change_notify": "При изменении валюты или месячного лимита, остаток будет также изменен",
		"comment": "Комментарий",
		"balance": "Баланс"
	}
};