module.exports = {
	"system_settings": {
		"page_title": "Платежные системы",
		"update_success": "Настройки успешно обновлены",
		"edit_breadcrumb": "Редактирование настроек системы {system}",
		"name": "Кодовое имя",
		"title": "Название",
		"status": "Статус",
		"status_on": "Включена",
		"status_off": "Отключена",
		"aliases": "Псевдонимы",
		"localized_titles": "Локализованные названия",
		"jurisdictions": "Юрисдикции",
		"actions": "Действия",
		"type": "Тип",
		"payment_loop": "Платежный цикл",
		"comment": "Комментарий",
		"types": {
			"banking": "Банкинги",
			"card": "Карты",
			"card_alt": "Карты альтернативные",
			"prepaid_card": "Карты предоплаты",
			"cash": "Наличные",
			"cryptocoin": "Криптовалюты",
			"mobile": "Мобильные",
			"vouchers": "Ваучеры",
			"e-wallet": "Электронные кошельки",
			"other": "Другое"
		}
	}
};