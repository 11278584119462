module.exports = {
	"widget": {
		"title": "Test widget",
		"new": "New widget",
		"customer_country": "Customer country",
		"new_customer": "New customer",
		"only_base_checks": "Skip detailed checks (VIP etc.)",
		"token_country": "Country by IP",
		"full": "Full",
		"mobile_app": "Mobile app",
		"one_click_common": "1-click non game",
		"one_click_mobile_app": "1-click mobile app",
		"one_click_mobile_app_common": "1-click mobile app non game",
		"common": "Common",
		"game": "Game",
		"vip_page": "VIP page",
		"disabled_limits": "Disable limits",
		"split_cards": "Split cards",
		"initialization_error": "Some error has been occurred during the widget loading",
		"show_disabled_systems": "Show disabled systems",
		"enable_channel_selection": "Channel selection",
		"tabs": {
			"ui": "Widget",
			"deposit_systems": "Deposit systems",
			"withdraw_systems": "Withdrawal systems",
			"project_systems": "Project systems"
		},
		"systems_table": {
			"system": "System",
			"processor": "Processor",
			"channel_code": "Channel",
			"last_used_index": "Last used index",
			"last_used_index_tooltip": "Index of last usage of system by customer. Lower number means used later and higher position in list.",
			"priority": "Priority",
			"priority_tooltip": "Priority can be changed in system rules. Greater number means higher positions in list.",
			"alt_priority": "Alt priority",
			"alt_priority_tooltip": "First number - 4 means recent success, 3 means first deposit through alt, 2 means alt recovered from fail, 1 means there is recent fail and 0 means problem alt. Second number - alt priority configured through rules. First number have more priority than second. Bigger number means bigger priority.",
			"rating": "Rating in country",
			"rating_tooltip": "Rating means number of customers in that country that used that system. Greater number means higher position in list.",
			"priority_reason": "Priority reason",
			"status": "Status",
			"disable_reason": "Disable reason",
			"default_priority_reason": "Default",
			"disable_reason_type": {
				"processor_context_invalid": "Processor context is invalid",
				"processor_account_invalid": "Processor account is invalid",
				"payments_disabled": "Disabled by action ({id})",
				"system_is_hidden": "Hidden by action ({id})",
				"disabled_to_customer": "Disabled for a customer"
			},
			"limits": "Limits",
			"limits_tooltip": "In token currency",
			"limits_reason": "Limits reason",
			"limits_reason_type": {
				"processor_context": "Processor context",
				"action": "Action {id}"
			}
		}
	}
};