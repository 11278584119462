module.exports = {
	"order_failure_codes": {
		"processor": "Процессинг",
		"code": "Код отказа",
		"type": "Тип",
		"subtype": "Подтип",
		"message": "Сообщение",
		"actions": "Действия",
		"created_success": "Код отказа успешно создан",
		"updated_success": "Код отказа успешно обновлен",
		"removed_success": "Код отказа успешно удален",
		"new_failure_code": "Новый код отказа"
	}
};