/* istanbul ignore file */
import _ from "lodash"
import { Component } from "react"

import { makeEventEmitter } from "./EventEmitter"

const windowStores = []
window._stores || Object.defineProperty(window, "_stores", {
  get () {
    return windowStores.map(storage => storage.state)
  },
})

// Wrap the component with given store
// @connect((state, props) => ({ .... }))
// @connect(["key1", "key2"])
// @connect({ key1: "newKey1" })
const connect = (Store, mapper = s => s) => {
  const mapFunction = (state, props) => {
    if (_.isFunction(mapper)) return mapper(state, props)
    if (_.isArray(mapper)) return _.pick(state, mapper)
    if (_.isPlainObject(mapper)) {
      return _.mapKeys(_.pick(state, Object.keys(mapper)), (_, key) => mapper[key])
    }
    throw new Error("Unsupported kind of mapper. Use only array, function or plain object.")
  }

  return Component => props => (
    <Store.Consumer>
      {state => <Component {...props} {...mapFunction(state, props)} />}
    </Store.Consumer>
  )
}

const makeStore = (initialValue = {}, methods = {}) => {
  const emitter = makeEventEmitter()

  const store = { state: _.cloneDeep(initialValue) }

  class StoreConsumer extends Component {
    constructor () {
      super()
      this.state = {
        data: store.state,
      }
    }

    componentDidMount () {
      this.unsubscribe = emitter.subscribe(this.update)
    }

    componentWillUnmount () {
      this.unsubscribe()
    }

    update = data => {
      this.setState({ data })
    }

    render () {
      return this.props.children(this.state.data) || null
    }
  }

  windowStores.push(store)

  return {
    get state () {
      return store.state
    },
    setState (value) {
      const before = _.cloneDeep(store.state)
      store.state = value
      emitter.notify(value, before)
    },
    merge (value) {
      const before = _.cloneDeep(store.state)
      store.state = { ...store.state, ...value }
      emitter.notify(store.state, before)
    },
    reset (value = {}) {
      const before = _.cloneDeep(store.state)
      store.state = _.cloneDeep(initialValue)
      _.merge(store.state, value)
      emitter.notify(store.state, before)
    },
    replace (path, value) {
      const before = _.cloneDeep(store.state)
      _.set(store.state, path, value)
      emitter.notify(store.state, before)
    },
    subscribe: emitter.subscribe,
    Consumer: StoreConsumer,
    connect (mapper) { return connect(this, mapper) },
    ...methods,
  }
}

export default makeStore
