import AutherClient from "$services/AutherClient"
import UserStore from "$stores/UserStore"

export const resetTokens = (timeout = 2000) => setTimeout(() => {
  UserStore.save({ accessToken: null, refreshToken: null, authorized: false })
}, timeout)

export const setTokens = authorizationCode =>
  AutherClient.fetchTokens(authorizationCode)
    .then(response => response.json())
    .then(({ accessToken, refreshToken }) => UserStore.save({ accessToken, refreshToken }))
    .catch(() => {
      resetTokens()
    })
