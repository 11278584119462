module.exports = {
	"balances": {
		"title": "Processor balances",
		"group_sum": "Group sum",
		"total_sum": "Total sum",
		"account": "Account",
		"balance": "Balance",
		"account_currency_balance": "Account currency balance",
		"refreshed": "Refreshed",
		"alert_min_sum": "Alert sum amount",
		"remove_confirmation": "Are you sure to delete this record?",
		"no_results": "No results. Try a different date."
	}
};