import escape from "escape-html"
import iziToast from "izitoast"

import checkIcon from "$icons/check.svg"
import crossIcon from "$icons/times.svg"

function toast (message, color, iconUrl) {
  iziToast.show({
    message: `<div style="white-space: pre-line">${escape(message)}</div>`,
    color,
    progressBar: false,
    iconUrl,
  })
}

export function alertSuccess (message) {
  toast(message, "green", checkIcon)
}

export function alertFailure (message) {
  toast(message, "red", crossIcon)
}
