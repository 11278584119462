module.exports = {
	"customer": {
		"title": "Игроки",
		"player": "Игрок",
		"profile": "Профиль игрока",
		"orders": {
			"title": "Заявки"
		},
		"queue": {
			"title": "Очередь заявок на выплату"
		},
		"turnover": {
			"title": "Оборот",
			"enough": "Достаточный",
			"not_enough": "Недостаточный"
		},
		"purses": {
			"title": "Кошельки",
			"purse_saved": "Кошелек сохранен"
		},
		"details": {
			"title": "Детали игрока",
			"balance": "Баланс",
			"name": "Ф.И.",
			"first_name": "Имя",
			"last_name": "Фамилия",
			"phone": "Телефон",
			"email": "Email",
			"phone_alt": "Альтернативный телефон",
			"email_alt": "Альтернативный email",
			"birthdate": "Дата рождения",
			"city": "Город",
			"address": "Адрес",
			"zipcode": "Почтовый индекс",
			"deposit_amount": "Сумма депозитов",
			"withdraw_amount": "Сумма выплат",
			"frozen_withdraw_balance": "Баланса заморожено выплатами",
			"disabled_payment_systems": "Забаненные платежные системы",
			"fraud_disabled_payment_systems": "Забаненные антифродом платежные системы",
			"systems_saved": "Настройки сохранены",
			"country": "Страна",
			"verification": {
				"title": "Верификация",
				"verified": "Верифицирован",
				"not_verified": "Не верифицирован"
			},
			"fraud_status": {
				"title": "Фрод статус",
				"suspicious": {
					"payouts_allowed": "Подозрительный (выплаты разрешены)",
					"payouts_blocked": "Подозрительный (выплаты заблокированы)",
					"auto_payouts_blocked": "Подозрительный (автовыплаты заблокированы)"
				},
				"not_suspicious": {
					"payouts_allowed": "ОК",
					"payouts_blocked": "Выплаты заблокированы",
					"auto_payouts_blocked": "Автовыплаты заблокированы"
				}
			},
			"jurisdiction": "Юрисдикция",
			"created_at": "Дата создания",
			"comments": "Комментарии от суппорта",
			"autopay_disabled": "Автовыплаты выключены",
			"limits": {
				"deposit": "Лимит депозитов",
				"payout": "Лимит выплат",
				"auto_payout": "Лимит автовыплат",
				"reasons": {
					"deposit": {
						"customer": "Самоограничение",
						"jurisdiction": "Юрисдикционный",
						"verification": "Верификации"
					},
					"payout": {
						"customer": "Персональный",
						"project": "Проектный",
						"default": "По умолчанию"
					}
				}
			},
			"test": "Тестовый",
			"loyal": "Лояльный",
			"vip": "VIP",
			"refcode": "Реферальный код",
			"account_type": "Тип аккаунта",
			"account_types": {
				"test": "Тестовый",
				"test_partner": "Тестовый партнер",
				"player": "Игрок",
				"partner": "Партнер"
			},
			"group_number": "Номер группы",
			"vwo_variations": "Вариации VWO"
		},
		"widget": {
			"title": "Виджет",
			"skip_all_checks": "Пропустить все проверки",
			"button": "Открыть виджет от лица игрока",
			"mobile": "Мобильная версия",
			"disable_limits": "Отключить лимиты",
			"show_disabled_systems": "Показывать отключенные системы",
			"enable_real_payment": "Реальный платеж",
			"enable_channel_selection": "Выбор канала"
		},
		"free_deposit_limits": {
			"title": "Лимиты бесплатных депозитов",
			"amount": "Сумма",
			"monthly_amount": "Месячный лимит",
			"currency": "Валюта",
			"enabled": "Включено",
			"actions": "Действия",
			"no_limits": "У игрока отсутствуют лимиты бесплатных депозитов"
		},
		"free_deposit_limit_logs": {
			"title": "Логи лимитов фридепов"
		},
		"token_details": "Детали последней сессии",
		"sessions": {
			"title": "Сессии"
		}
	}
};