module.exports = {
	"processors": {
		"update_success": "Processor successfully updated",
		"details": "Details",
		"page_title": "Processors",
		"name": "Code name",
		"title": "Title",
		"status": "Status",
		"jurisdictions": "Jurisdictions",
		"actions": "Actions",
		"url": "URL",
		"deposit_systems": "Supported deposit PS",
		"withdraw_systems": "Supported withdraw PS",
		"reports_timezone": "Reports timezone",
		"active": "Active",
		"edit": "Edit",
		"invalid_name": "Please enter valid project code name",
		"invalid_title": "Please enter valid project title",
		"invalid_url": "Please enter valid url",
		"refunds_mode": "Refunds mode",
		"code": "Code",
		"rules": "Rules",
		"settings_link": "Settings of {name}",
		"comment": "Comment",
		"autorefund": "Autorefunds enabled"
	}
};