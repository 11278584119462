/* istanbul ignore file */
/* eslint-disable no-console */

import axios from "axios"

import config from "$config"
import { getAccessToken } from "$utils/token"

import { processError } from "./ErrorProcessor"
import { processSuccess } from "./SuccessProcessor"

const requestID = (() => {
  let count = 1
  return () => (count = count + 1)
})()

const needLog = ({ url }) => {
  const { logRequest } = config

  return logRequest.enabled === "true" && !logRequest.ignore.some(u => url.includes(u))
}

const httpLog = (id, color, ...params) => {
  const style = [
    `background: ${color}`,
    "border-radius: 3px",
    "padding: 1px 3px",
    "color: #fff",
    "font-weight: 700",
  ].join(";")

  console.log(`%c#${id} [HTTP]`, style, ...params)
}

const logRequest = (id, { url, method, body }) => httpLog(id, "#808e9b", method, url, body)

const logResponse = (id, { url, method, status, data }) => {
  const color = status < 400 ? "#05c46b" : "#ff3f34"
  httpLog(id, color, method, status, url, data)
}

const getFakeResponse = ({ mockedResponse, mockedStatus }) => {
  const status = mockedStatus || 200
  const isSuccess = status === 200
  const statusText = isSuccess ? "OK" : "ERROR"

  return { data: mockedResponse, status, statusText }
}

const buildUrl = url => {
  const path = `/admin${url}`
  return config.backendUrl ? new URL(path, config.backendUrl).toString() : path
}

const getRealRequest = ({ url, method, responseType = null, body = {} }) => {
  const dataKey = method === "GET" ? "params" : "data"
  return {
    url: buildUrl(url),
    method: method.toLowerCase(),
    responseType,
    [dataKey]: { ...body, token: getAccessToken() },
  }
}

const fakePerform = request => new Promise((resolve, reject) => {
  const fakeResponse = getFakeResponse(request)
  const isSuccess = fakeResponse.status === 200
  const performFn = () => {
    isSuccess
      ? resolve(fakeResponse)
      // eslint-disable-next-line prefer-promise-reject-errors
      : reject({ response: fakeResponse })
  }

  setTimeout(performFn, request.throttleBy || 0)
})

const fetch = request => (config.backendUrl ? axios(getRealRequest(request)) : fakePerform(request))

const perform = request => {
  const id = requestID()

  if (needLog(request)) logRequest(id, request)
  // to avoid calling `then` callback after request failing
  return new Promise((resolve, reject) => {
    fetch(request)
      .then(({ status, data }) => {
        if (needLog(request)) logResponse(id, { ...request, data, status })
        processSuccess(data, request)
        resolve(data)
      })
      .catch(error => {
        if (error.response) {
          const { data, status } = error.response || {}
          logResponse(id, { ...request, data, status })
          processError(error.response.data.error, request)
        }
        reject(error)
      })
  })
}

const makePerform = requestFn => body => perform(requestFn(body))

const api = definitions => {
  return Object.entries(definitions).reduce((mem, [k, r]) => {
    return { ...mem, [k]: makePerform(r) }
  }, {})
}

export default api
