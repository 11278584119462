module.exports = {
	"projects": {
		"title": "Проекты",
		"code_name": "Кодовое имя",
		"name": "Название",
		"jurisdictions": "Юрисдикции",
		"status": "Статус",
		"actions": "Действия",
		"new_project": "Новый проект",
		"status_on": "Включен",
		"status_off": "Выключен",
		"new": "Создание",
		"editing": "Редактирование",
		"short_name": "Короткое имя",
		"standard_mode": "Стандартный режим",
		"standard_theme": "Стандартная тема",
		"api_link": "API адрес",
		"api_key": "API ключ",
		"hd_url": "URL заявки в HD",
		"4k_url": "URL заявки в 4k",
		"send_requests": "Отправлять запросы",
		"active": "Активен",
		"autopay_disabled": "Автовыплата отключена",
		"system_key": "Ключ для системы {system}",
		"site_id": "ID сайта",
		"salt": "Соль",
		"mt_site_id": "ID сайта MT",
		"mt_salt": "Соль MT",
		"skip_iframe": "Пропустить iframe",
		"orangepay": "Настройки OrangePay",
		"token": "Токен",
		"key": "Ключ",
		"code": "Код",
		"login": "Логин",
		"password": "Пароль",
		"ecopayz": "Настройки ecoPayz",
		"accounts": "Аккаунты",
		"coinpayments": "Настройки CoinPayments",
		"public_key": "Публичный ключ",
		"private_key": "Приватный ключ",
		"zimpler": "Настройки Zimpler",
		"klarna": "Настройки Klarna",
		"gate2025": "Настройки Gate2025",
		"project_id": "ID проекта",
		"secret_key": "Секретный ключ",
		"cardpay": "Настройки CardPay",
		"terminal_code": "Код терминала",
		"terminal_password": "Пароль терминала",
		"callback_password": "Пароль нотификаций",
		"created_success": "Проект успешно создан",
		"updated_success": "Проект успешно обновлен",
		"add_currency": "Добавить валюту",
		"choose_currency": "Выберите валюту",
		"customer_id": "ID игрока",
		"main_settings": "Основные настройки",
		"errors": {
			"code_name": "Введите кодовое имя проекта",
			"name": "Введите название проекта",
			"short_name": "Введите короткое имя проекта",
			"api_link": "Введите API адрес проекта",
			"api_key": "Введите API ключ проекта",
			"jurisdictions": "Выберите юрисдикции проекта"
		}
	}
};