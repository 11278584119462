import { LocalStorage } from "$utils/storage"

const DEFAULT = "ru"

export const makeTranslations = translations => (
  Object.values(translations)
    .reduce((mem, v) => ({ ...mem, ...v }), {})
)

export const getCurrentLocale = () => LocalStorage.getItem("locale") || DEFAULT

export const languages = ["ru", "en"]

export const changeLocale = locale => {
  LocalStorage.setItem("locale", locale)
  document.location.reload()
}
