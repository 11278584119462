module.exports = {
	"processor_callbacks": {
		"group_title": "Коллбеки процессингов",
		"retry_message": "Коллбек отправлен повторно",
		"dispatch": {
			"title": "Отправка коллбеков",
			"callback_type": "Тип коллбека",
			"callback_body": "Коллбек",
			"successfully_sent": "Коллбек успешно отправлен"
		},
		"list": {
			"title": "Список коллбеков",
			"failure_message_checkbox": "Только пустое сообщение об ошибке (null)",
			"callback_body_checkbox": "Только пустое тело коллбека (null)"
		},
		"details": {
			"page_title": "Коллбек {id}",
			"title": "Детали коллбека",
			"retry": "Отправить заново",
			"to_list": "К списку коллбеков"
		},
		"callback": {
			"id": "ID",
			"processor": "Процессинг",
			"status": "Статус",
			"failure_message": "Сообщение об ошибке",
			"callback_body": "Тело",
			"created_at": "Время создания",
			"updated_at": "Время последнего обновления"
		},
		"status": {
			"initial": "Начальный",
			"queued": "В очереди",
			"failed": "Ошибка",
			"success": "Успешный"
		}
	}
};