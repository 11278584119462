module.exports = {
	"action_log": {
		"create": "Fields",
		"update": "Changed fields",
		"delete": "Fields",
		"reorder": "Changed order",
		"field": "Field",
		"value": "Value",
		"changes": "Changes",
		"new_order": "New order",
		"reason": "Reason",
		"log_type": {
			"orders": "Orders",
			"projects": "Projects",
			"session": "Sessions",
			"system_settings": "System settings",
			"systems": "Payment systems",
			"users": "Users",
			"order_comments": "Order comments",
			"banks": "Banks",
			"processors": "Processors",
			"customers": "Customers",
			"account_balances": "Balances",
			"alerts": "Alerts",
			"order_failure_codes": "Order failure codes",
			"customer_free_limits": "Free deposit limits",
			"processor_accounts": "Processor accounts",
			"saved_purses": "Saved purses",
			"rules": "Rules",
			"unknown": "Unknown",
			"custom_rates": "Custom rates",
			"monitor_volume": "Monitor volumes"
		},
		"action": {
			"create": "Create",
			"duplicate": "Duplicate",
			"update": "Update",
			"delete": "Delete",
			"reorder": "Reorder",
			"update_status": "Update status",
			"login": "Login",
			"change_password": "Change password",
			"payout": "Payout",
			"move_to_manual_queue": "Move to manual queue",
			"move_to_pending": "Move to pending status",
			"payout_at_time": "Payout at time",
			"reject": "Reject",
			"autoreject": "Autoreject",
			"repay": "Repay",
			"refund": "Request refund",
			"declare_refund": "Declare taken refund",
			"redeposit": "Re-deposit",
			"move_to_auto_queue": "Move to auto queue",
			"ban_card": "Ban card",
			"unban_card": "Unban card",
			"cancel": "Cancel in processor"
		},
		"table": {
			"user": "User",
			"type": "Type",
			"action": "Action",
			"object": "Object",
			"description": "Description",
			"date": "Date",
			"additional_info": "Add. info"
		}
	}
};