import { alertFailure } from "$utils/alerts"
import { resetTokens } from "$utils/auther"
import translateErrorResponse from "$utils/translateErrorResponse"

export const processError = ({ code, params } = {}) => {
  alertFailure(translateErrorResponse(code, params))

  code === "invalid_token" && resetTokens()

  if (code === "invalid_token") throw new Error("AuthError")
}
