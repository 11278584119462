module.exports = {
	"free_deposit_limits": {
		"title": "Free deposit limits",
		"customer": "Customer",
		"email": "Email",
		"daily_amount": "Daily rest",
		"weekly_amount": "Weekly rest",
		"monthly_amount": "Monthly rest",
		"daily_limit": "Daily limit",
		"weekly_limit": "Weekly limit",
		"monthly_limit": "Monthly limit",
		"currency": "Currency",
		"enabled": "Enabled",
		"created_at": "Created at",
		"actions": "Actions",
		"created_success": "Limit is successfully created",
		"updated_success": "Limit is successfully updated",
		"limit_change_notify": "If you change the currency or monthly limit, the remaining amount will also be changed",
		"comment": "Comment",
		"balance": "Balance"
	}
};