module.exports = {
	"alerts": {
		"title": "Алерты",
		"alert_type": "Тип",
		"message": "Сообщение",
		"importance": "Важность",
		"event_count": "Количество алертов",
		"muted_until": "Игнорировать до",
		"created_at": "Создано",
		"updated_at": "Обновлено",
		"last_event_time": "Время последнего появления",
		"not_ignore": "Не игнорировать",
		"importance_type": {
			"urgent": "Срочно",
			"important": "Высокая",
			"regular": "Средняя",
			"low": "Низкая"
		},
		"muted_period": {
			"1hour": "1 час",
			"6hours": "6 часов",
			"24hours": "24 часа"
		},
		"type": {
			"auto_exchange": "Автообмен валюты",
			"auto_refund": "Авторефанд",
			"bad_transition": "Запрещённый переход статуса",
			"billing_amount_change": "Изменение суммы платежа",
			"deposit_failure": "Ошибка депозита",
			"deposit_not_allowed": "Депозит не разрешен",
			"duplicate_operations": "Дублированные операции",
			"final_status_transition": "Переход финального статуса",
			"insufficient_balance": "Недостаточный баланс для выплаты",
			"low_balance": "Низкий баланс процессинга",
			"missed_banks": "Новый банк",
			"payment_prohibited": "Транзакция запрещена",
			"payout_error": "Ошибка выплаты",
			"processor_alerts": "Сообщение от процессинга",
			"processor_callback_failure": "Ошибка обработки коллбека",
			"project_notification_error": "Ошибка проектной нотификации",
			"refund_error": "Ошибка рефанда",
			"sentry": "Проблема в Sentry",
			"withdrawal_sum": "Ошибка суммы выплаты",
			"unused_rule_segments_warning": "Неиспользуемые сегменты правил",
			"monitoring_volume": "Мониторинг объемов"
		}
	}
};