module.exports = {
	"reports": {
		"group_title": "Reports",
		"common": {
			"date": "Date",
			"week": "Week",
			"month": "Month",
			"year": "Year",
			"currency": "Currency",
			"order_currency": "Order currency",
			"billing_currency": "Billing currency",
			"processor": "Processor",
			"system": "PS",
			"project": "Project",
			"type": "Type",
			"locale": "Locale",
			"customer_country": "Customer country",
			"token_country": "Country by IP",
			"jurisdiction": "Jurisdiction",
			"bank": "Bank",
			"platform": "Platform",
			"user": "User",
			"customer_area": "Customer area",
			"token_area": "IP area",
			"load_report_message": "Set filters and click Show button",
			"widget_mode": "Widget mode",
			"billing_type": "Billing type",
			"ftd": "FTD",
			"refcode": "Refcode",
			"purse_country": "Purse country",
			"failure_type": "Failure type",
			"failure_subtype": "Failure subtype",
			"failure_code": "Failure code",
			"failure_message": "Failure message",
			"vwo_variation": "VWO Variation",
			"card_type": "Card type"
		},
		"deposit_passability": {
			"title": "Deposit passability",
			"total_count": "Total deposits",
			"success_count": "Paid",
			"user_count": "Total users",
			"successful_user_count": "Successful users",
			"terminal": "Terminal failure",
			"timeout": "Timeout",
			"success_count_without_timeouts": "Success without timeouts",
			"processing_time_without_timeouts": "Average processing time without timeouts"
		},
		"withdraw_passability": {
			"title": "Withdraw passability",
			"total_count": "Total withdraw",
			"success_count": "Success",
			"rejected_count": "Rejected"
		},
		"passability": {
			"processing_time": "Average processing time",
			"user_failure": "User failure",
			"processor_decline": "Processor decline",
			"insufficient_funds": "Insufficient funds"
		},
		"fees": {
			"title": "Fees",
			"deposit_amount": "Deposit amount",
			"deposit_billing_amount": "Deposit amount (billing)",
			"deposit_count": "Deposit count",
			"withdraw_amount": "Withdrawal amount",
			"withdraw_billing_amount": "Withdrawal amount (billing)",
			"withdraw_count": "Withdrawal count",
			"deposit_fee_external_amount": "Fee amount (External, IN)",
			"withdraw_fee_external_amount": "Fee amount (External, OUT)",
			"deposit_fee_amount": "Fee amount (Calculated, IN)",
			"deposit_fee_percent": "Fee % (IN)",
			"withdraw_fee_amount": "Fee amount (Calculated, OUT)",
			"withdraw_fee_percent": "Fee % (OUT)",
			"fee_amount": "Fee amount (IN + OUT)",
			"fee_percent": "Fee % (IN + OUT)",
			"fee_sum": "Fee turnover, %"
		},
		"customers_turnover": {
			"title": "Customers turnover",
			"player": "Player",
			"deposit_amount": "Deposit amount",
			"withdraw_amount": "Withdrawal amount"
		},
		"transition_history": {
			"title": "Withdraws: Transition history",
			"page_title": "Orders status history",
			"old_status": "Old status",
			"new_status": "New status",
			"change_interval": "Status changing duration",
			"created_at": "Status changing date",
			"order_created_at": "Order creation date"
		},
		"transactions": {
			"title": "Transactions report",
			"deposit_amount": "Deposit amount",
			"deposit_count": "Deposit count",
			"withdraw_amount": "Withdrawal amount",
			"withdraw_count": "Withdrawal count",
			"deposit_fee_amount": "Fee amount (Calculated, IN)",
			"deposit_fee_external_amount": "Fee amount (External, IN)",
			"withdraw_fee_amount": "Fee amount (Calculated, OUT)",
			"withdraw_fee_external_amount": "Fee amount (External, OUT)",
			"fee_sub": "Fee in-out, %",
			"fee_sum": "Fee turnover, %",
			"refund_amount": "Refunds amount",
			"refund_count": "Refunds count",
			"processor_channel": "Processor channel",
			"failed_deposit_count_without_timeouts": "Failed deposit count (without timeouts)",
			"failed_withdraw_count_without_timeouts": "Failed withdrawal count (without timeouts)"
		},
		"global_orders": {
			"title": "Global orders report",
			"total_deposit_count": "Total deposit count",
			"successful_deposit_count": "Successful deposit count",
			"successful_deposit_sum": "Successful deposit sum",
			"successful_deposit_billing_sum": "Successful deposit sum (billing)",
			"deposit_fee_sum": "Fee sum amount (Calculated, IN)",
			"deposit_customer_count": "Deposit customer count",
			"successful_deposit_customer_count": "Successful deposit customer count",
			"successful_withdraw_count": "Successful withdraw count",
			"successful_withdraw_sum": "Successful withdraw sum",
			"successful_withdraw_billing_sum": "Successful withdraw sum (billing)",
			"withdraw_fee_sum": "Fee sum amount (Calculated, OUT)",
			"withdraw_fee_external_sum": "Fee sum amount (External, IN)",
			"deposit_fee_external_sum": "Fee sum amount (External, OUT)"
		}
	}
};