module.exports = {
	"alerts": {
		"title": "Alerts",
		"alert_type": "Type",
		"message": "Message",
		"importance": "Importance",
		"event_count": "Alert count",
		"muted_until": "Muted until",
		"created_at": "Created at",
		"updated_at": "Updated at",
		"last_event_time": "Last event time",
		"not_ignore": "Don't ignore",
		"importance_type": {
			"urgent": "Urgent",
			"important": "Important",
			"regular": "Regular",
			"low": "Low"
		},
		"muted_period": {
			"1hour": "1 hour",
			"6hours": "6 hours",
			"24hours": "24 hours"
		},
		"type": {
			"auto_exchange": "Auto exchange",
			"auto_refund": "Auto refund",
			"bad_transition": "Bad status transition",
			"billing_amount_change": "Billing amount change",
			"deposit_failure": "Deposit failure",
			"deposit_not_allowed": "Deposit not allowed",
			"duplicate_operations": "Duplicate operations",
			"final_status_transition": "Final status transition",
			"insufficient_balance": "Insufficient balance for withdraw",
			"low_balance": "Processor low balance",
			"missed_banks": "Missed bank",
			"payment_prohibited": "Payment prohibited",
			"payout_error": "Payout error",
			"processor_alerts": "Processor alert",
			"processor_callback_failure": "Processor callback failure",
			"project_notification_error": "Project notification error",
			"refund_error": "Refund error",
			"sentry": "Sentry issue",
			"withdrawal_sum": "Withdrawal sum error",
			"unused_rule_segments_warning": "Unused rule segments",
			"monitoring_volume": "Monitoring volume"
		}
	}
};