module.exports = {
	"customer": {
		"title": "Customers",
		"player": "Player",
		"profile": "Profile",
		"orders": {
			"title": "Orders"
		},
		"queue": {
			"title": "Payout queue"
		},
		"turnover": {
			"title": "Player turnover",
			"enough": "Enough",
			"not_enough": "Not enough"
		},
		"purses": {
			"title": "Purses",
			"purse_saved": "Purse saved"
		},
		"details": {
			"title": "Details",
			"balance": "Balance",
			"name": "Name",
			"first_name": "First name",
			"last_name": "Last name",
			"phone": "Phone",
			"email": "Email",
			"phone_alt": "Alternative phone",
			"email_alt": "Alternative email",
			"birthdate": "Birthdate",
			"city": "City",
			"address": "Address",
			"zipcode": "Zipcode",
			"deposit_amount": "Deposit amount",
			"withdraw_amount": "Withdrawal amount",
			"frozen_withdraw_balance": "Frozen withdraw balance",
			"disabled_payment_systems": "Disabled payment systems",
			"fraud_disabled_payment_systems": "Disabled payment systems by antifraud",
			"systems_saved": "Systems saved",
			"country": "Country",
			"verification": {
				"title": "Verification",
				"verified": "Verified",
				"not_verified": "Not verified"
			},
			"fraud_status": {
				"title": "Fraud status",
				"suspicious": {
					"payouts_allowed": "Suspicious (payouts are allowed)",
					"payouts_blocked": "Suspicious (payouts are blocked)",
					"auto_payouts_blocked": "Suspicious (auto payouts are blocked)"
				},
				"not_suspicious": {
					"payouts_allowed": "OK",
					"payouts_blocked": "Payouts are blocked",
					"auto_payouts_blocked": "Auto payouts are blocked"
				}
			},
			"jurisdiction": "Jurisdiction",
			"created_at": "Creation date",
			"comments": "Comments from support",
			"autopay_disabled": "Autopay disabled",
			"limits": {
				"deposit": "Deposit limits",
				"payout": "Payout limits",
				"auto_payout": "Auto payout limit",
				"reasons": {
					"deposit": {
						"customer": "Self-restriction",
						"jurisdiction": "Jurisdiction",
						"verification": "Verification"
					},
					"payout": {
						"customer": "Personal",
						"project": "Project",
						"default": "Default"
					}
				}
			},
			"test": "Test",
			"loyal": "Loyal",
			"vip": "VIP",
			"refcode": "Refcode",
			"account_type": "Account type",
			"account_types": {
				"test": "Test",
				"test_partner": "Test partner",
				"player": "Player",
				"partner": "Partner"
			},
			"group_number": "Group number",
			"vwo_variations": "Vwo variations"
		},
		"widget": {
			"title": "Widget",
			"skip_all_checks": "Skip all checks",
			"button": "Open widget by customer",
			"mobile": "Mobile version",
			"disable_limits": "Disable limits",
			"show_disabled_systems": "Show disabled systems",
			"enable_real_payment": "Real payment",
			"enable_channel_selection": "Channel selection"
		},
		"free_deposit_limits": {
			"title": "Free deposit limits",
			"amount": "Amount",
			"monthly_amount": "Monthly amount",
			"currency": "Currency",
			"enabled": "Enabled",
			"actions": "Actions",
			"no_limits": "Customer hasn't got free deposit limits"
		},
		"free_deposit_limit_logs": {
			"title": "Free dep limit logs"
		},
		"token_details": "Last session details",
		"sessions": {
			"title": "Sessions"
		}
	}
};