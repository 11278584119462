module.exports = {
	"monitor_volumes": {
		"title": "Мониторинг объемов",
		"name": "Имя",
		"period_type": "Период",
		"type": "Тип",
		"summ": "Сумма",
		"channels": "Каналы",
		"previous_period": "Предыдущий период",
		"current_period": "Текущий период",
		"enabled": "Включен",
		"new": "Новый",
		"created_success": "Мониторинг объемов успешно создан",
		"updated_success": "Мониторинг объемов успешно обновлен",
		"amount": "Сумма",
		"currency": "Валюта",
		"coefficient": "Множитель",
		"day": "День",
		"week": "Неделя",
		"month": "Месяц",
		"quarter": "Квартал",
		"max_volume": "Достижение объема",
		"support_volume": "Поддержание объема",
		"actions": "Действия",
		"edit": "Редактировать",
		"editing": "Редактирование",
		"comment": "Комментарий"
	}
};