import t from "$t"

const mapCodeToTranslationKey = code => `errors.${code}`

const translateErrorResponse = (code, params) => {
  const result = t(mapCodeToTranslationKey(code), { defaultKey: "common.unknown_error", params })

  return result.replace("<br>", "\n")
}

export default translateErrorResponse
