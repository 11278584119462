module.exports = {
	"balances": {
		"title": "Балансы по процессингам",
		"group_sum": "Сумма по группе",
		"total_sum": "Общая сумма балансов",
		"account": "Аккаунт",
		"balance": "Баланс",
		"account_currency_balance": "Баланс в валюте аккаунта",
		"refreshed": "Обновлено",
		"alert_min_sum": "Мин. сумма для нотификации",
		"remove_confirmation": "Вы уверены, что хотите удалить запись?",
		"no_results": "Нет результатов. Попробуйте другую дату."
	}
};