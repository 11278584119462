module.exports = {
	"projects": {
		"title": "Projects",
		"code_name": "Code name",
		"name": "Name",
		"jurisdictions": "Jurisdictions",
		"status": "Status",
		"actions": "Actions",
		"new_project": "New project",
		"status_on": "On",
		"status_off": "Off",
		"new": "New",
		"editing": "Editing",
		"short_name": "Short name",
		"standard_mode": "Standard mode",
		"standard_theme": "Standard theme",
		"api_link": "API link",
		"api_key": "API key",
		"hd_url": "Order URL in HD",
		"4k_url": "Order URL in 4k",
		"send_requests": "Send requests",
		"active": "Active",
		"autopay_disabled": "Autopay disabled",
		"system_key": "Key for system {system}",
		"site_id": "Site ID",
		"salt": "Salt",
		"mt_site_id": "MT site ID",
		"mt_salt": "MT salt",
		"skip_iframe": "Skip iframe",
		"orangepay": "OrangePay settings",
		"token": "Token",
		"key": "Key",
		"code": "Code",
		"login": "Login",
		"password": "Password",
		"ecopayz": "ecoPayz settings",
		"accounts": "Accounts",
		"coinpayments": "CoinPayments settings",
		"public_key": "Public key",
		"private_key": "Private key",
		"zimpler": "Zimpler settings",
		"klarna": "Klarna settings",
		"gate2025": "Gate2025 settings",
		"project_id": "Project ID",
		"secret_key": "Secret key",
		"cardpay": "CardPay settings",
		"terminal_code": "Terminal code",
		"terminal_password": "Terminal password",
		"callback_password": "Callback password",
		"created_success": "Project succesfully created",
		"updated_success": "Project succesfully updated",
		"add_currency": "Add currency",
		"choose_currency": "Choose currency",
		"customer_id": "Customer ID",
		"main_settings": "Main settings",
		"errors": {
			"code_name": "Enter code name of the project",
			"name": "Enter name of the project",
			"short_name": "Enter short name of the project",
			"api_link": "Enter API link of the project",
			"api_key": "Enter API key of the project",
			"jurisdictions": "Choose project's jurisdictions"
		}
	}
};