module.exports = {
	"processors": {
		"update_success": "Процессинг успешно обновлен",
		"details": "Детали",
		"page_title": "Процессинги",
		"name": "Кодовое имя",
		"title": "Название",
		"status": "Статус",
		"jurisdictions": "Юрисдикции",
		"actions": "Действия",
		"url": "URL",
		"deposit_systems": "Поддерживаемые ПС для депозитов",
		"withdraw_systems": "Поддерживаемые ПС для выплат",
		"reports_timezone": "Часовой пояс для отчетов",
		"active": "Активен",
		"edit": "Редактирование",
		"invalid_name": "Введите кодовое имя проекта",
		"invalid_title": "Введите название проекта",
		"invalid_url": "Введите корректный URL",
		"refunds_mode": "Режим рефандов",
		"code": "Код",
		"rules": "Правила",
		"settings_link": "Настройки {name}",
		"comment": "Комментарий",
		"autorefund": "Авторефанды включены"
	}
};