module.exports = {
	"processor_fees": {
		"title": "Processor fees",
		"processor": "Processor",
		"processor_channel": "Processor channel",
		"system": "System",
		"type": "Type",
		"period": "Period",
		"fees": "Fee",
		"additional": "Add. info",
		"additional_fees": "Additional fees",
		"min_amount": "Min. amoun",
		"max_amount": "Max. amount",
		"by_countries": "Fees by countries",
		"now": "now",
		"and": "and"
	}
};