module.exports = {
	"system_settings": {
		"page_title": "Payment systems",
		"update_success": "Settings successfully updated",
		"edit_breadcrumb": "Edit {system} system settings",
		"name": "Code name",
		"title": "Title",
		"status": "Status",
		"status_on": "On",
		"status_off": "Off",
		"allowed": "Allowed",
		"banned": "Banned",
		"aliases": "Aliases",
		"jurisdictions": "Jurisdictions",
		"actions": "Actions",
		"type": "Type",
		"localized_titles": "Localized titles",
		"payment_loop": "Payment loop",
		"comment": "Comment",
		"types": {
			"banking": "Banking",
			"card": "Card",
			"card_alt": "Alternative card",
			"prepaid_card": "Prepaid card",
			"cash": "Cash",
			"cryptocoin": "Cryptocurrency",
			"mobile": "Mobile",
			"vouchers": "Vouchers",
			"e-wallet": "E-wallet",
			"other": "Other"
		}
	}
};