export const makeEventEmitter = () => {
  const emitter = {
    subscribers: [],
    subscribe: fn => {
      emitter.subscribers.push(fn)
      return () => {
        emitter.subscribers = emitter.subscribers.filter(sub => sub !== fn)
      }
    },
    notify: (...data) => emitter.subscribers.forEach(sub => sub(...data)),
  }
  return emitter
}
