module.exports = {
	"common": {
		"channel": "Канал",
		"merchant": "Мерчант",
		"required": "Обязательный",
		"show": "Показать",
		"open": "Открыть",
		"amount": "Сумма",
		"currency": "Валюта",
		"countries": "Страны",
		"comma_separated": "через запятую",
		"save": "Сохранить",
		"add": "Добавить",
		"create": "Создать",
		"create_breadcrumb": "Создание",
		"edit": "Редактировать",
		"delete": "Удалить",
		"cancel": "Отмена",
		"on": "Включен",
		"off": "Выключен",
		"follow": "Перейти",
		"reset": "Сбросить",
		"total": "Итого",
		"month": "Мес",
		"week": "Нед",
		"day": "День",
		"hours": "Часы",
		"short_day": "д",
		"short_hour": "ч",
		"short_minute": "м",
		"short_second": "с",
		"other_platforms": "Остальные платформы",
		"default": "По умолчанию",
		"country": "Страна",
		"fee": "Комиссия",
		"deposit": "Депозит",
		"deposits": "Депозиты",
		"deposit_types": {
			"normal": "Обычный",
			"one_click": "1-click",
			"paynplay": "Pay N Play"
		},
		"withdraw": "Выплата",
		"withdraws": "Выплаты",
		"withdraw_types": {
			"auto": "Авто",
			"manual": "Ручная",
			"semiauto": "Полуавто"
		},
		"project": "Проект",
		"locale": "Локаль",
		"jurisdiction": "Юрисдикция",
		"jurisdictions": "Юрисдикции",
		"mode": "Режим",
		"search": "Поиск",
		"not_selected": "Не выбрано",
		"from": "От",
		"to": "До",
		"yes": "Да",
		"no": "Нет",
		"tag": "Тег",
		"typing_here": "Введите здесь",
		"mobile_version": "Мобильная версия",
		"is_landscape": "Портретный режим",
		"submit": "Отправить",
		"nothing_found": "Ничего не найдено",
		"invalid_url": "Невалидный URL",
		"unknown_error": "Неизвестная ошибка",
		"order": "Заявка",
		"scope": "Тип",
		"card": "Карта",
		"session": "Сессия",
		"system": "Система",
		"user": "Пользователь",
		"customer": "Игрок",
		"bank": "Банк",
		"processor": "Процессинг",
		"processor_account": "Аккаунт процессинга",
		"account_balance": "Баланс",
		"order_failure_code": "Код отказа заявок",
		"success": "Успешно",
		"error": "Неуспешно",
		"device": "Устройство",
		"browser": "Браузер",
		"platform": "Платформа",
		"user_agent": "User Agent",
		"can_process": "Обработать?",
		"back": "Назад",
		"close": "Закрыть",
		"datepicker_placeholder": "Выбрать дату",
		"copied": "Скопировано",
		"enabled": "Включен",
		"actions": "Действия",
		"reconciliation": "Сверка",
		"alert": "Алерт",
		"period_types": {
			"created_at": "По дате создания",
			"status_updated_at": "По дате обновления статуса",
			"processed_at": "По дате обработки процессинга"
		},
		"sort_types": {
			"period": "По периоду",
			"amount": "По сумме"
		},
		"payment_status": {
			"initial": "Начальный",
			"paid": "Оплачена",
			"success": "Успешная",
			"requested": "Оплата запрошена",
			"pending": "Новая",
			"failed": "Отказ",
			"canceled": "Отменена",
			"rejected": "Отклонена",
			"scheduled": "Выплата запланирована",
			"credited": "Зачислена",
			"refunded": "Возвращена",
			"error": "Ошибка"
		},
		"fee_types": {
			"customer_fee": "Наша комиссия",
			"personal_fee": "Персональная комиссия",
			"project_fee": "Комиссия проекта"
		},
		"title": "Название",
		"filters": "Фильтры",
		"settings": "Настройки",
		"conditions": "Условия",
		"main": "Основное",
		"information": "Информация",
		"other": "Другое",
		"grouping": {
			"title": "Группировать",
			"other": "Остальные",
			"period": {
				"title": "По периоду",
				"days": "Дни",
				"week": "Недели",
				"month": "Месяцы",
				"year": "Годы"
			},
			"project": "По проекту",
			"by_processor": "По процессингу",
			"processor": "Процессинг",
			"processor_with_subprocessor": "Процессинг и подпроцессинг",
			"by_system": "По ПС",
			"system": "ПС",
			"system_with_subsystem": "ПС и подсистема",
			"customer_country": "По стране игрока",
			"token_country": "По стране по IP",
			"type": "По типу",
			"locale": "По локали",
			"currency": "По валюте",
			"fee_currency": "По валюте комиссии",
			"order_currency": "По валюте заявки",
			"jurisdiction": "По юрисдикции",
			"bank": "По банку",
			"report_currency": "Показать в валюте",
			"platform": "По платформе",
			"billing_currency": "По валюте платежа",
			"user": "По пользователю",
			"customer_area": "По региону игрока",
			"token_area": "По региону IP",
			"processor_channel": "По каналу процессинга",
			"widget_mode": "По режиму виджета",
			"billing_type": "По типу платежа",
			"ftd": "По FTD",
			"refcode": "По рефкоду",
			"purse_country": "По стране кошелька",
			"failure_type": "По типу отказа",
			"failure_subtype": "По подтипу отказа",
			"failure_code": "По коду отказа",
			"failure_message": "По сообщению отказа",
			"vwo_variation": "По вариации VWO",
			"card_type": "По типу карты"
		},
		"continents": {
			"AF": "Африка",
			"AS": "Азия",
			"EU": "Европа",
			"NA": "Северная Америка",
			"OC": "Океания",
			"SA": "Южная Америка"
		},
		"billing_types": {
			"normal": "Обычный",
			"free": "Бесплатный",
			"test": "Тестовый",
			"internal": "Внутренний"
		},
		"refund_modes": {
			"full": "Полные",
			"partial": "Частичные",
			"disabled": "Выключены"
		},
		"country_areas": {
			"ru": "Российская Федерация",
			"cis": "СНГ",
			"euro_union": "Европа (Евросоюз)",
			"not_euro_union": "Европа (Не Евросоюз)",
			"west_asia": "Западная Азия",
			"east_asia": "Восточная Азия",
			"africa": "Африка",
			"australia_and_oceania": "Австралия и Океания",
			"south_america": "Южная Америка",
			"north_america": "Северная Америка",
			"other": "Другие"
		},
		"payment_loop_types": {
			"brief": {
				"open": "Открытый",
				"closed": "Закрытый",
				"strictly_closed": "Строго закрытый"
			},
			"full": {
				"open": "Открытый",
				"closed": "Закрытый (требуется наличие депозита)",
				"strictly_closed": "Строго закрытый (требуется наличие кошелька)"
			}
		},
		"badge": {
			"enabled": "Включен",
			"disabled": "Выключен"
		},
		"saved_purse": "Сохраненный кошелек",
		"description": "Описание",
		"rule_segment": "Сегмент правила",
		"rule_action": "Действие правила",
		"rule": "Правило",
		"ok": "OK",
		"platform_type": {
			"mobile": "Смартфон",
			"desktop": "Десктоп",
			"other": "Другие"
		},
		"status": "Статус",
		"presets": {
			"title": "Выбранный пресет",
			"new": "+ Новый пресет",
			"new_preset_title": "Название пресета",
			"enter_title": "Введите название",
			"add": "Добавить",
			"cancel": "Отменить",
			"delete": "Удалить",
			"update": "Сохранить",
			"delete_confirmation": "Удалить пресет \"{title}\"?",
			"preset_updated": "Пресет обновлен"
		},
		"options": "Опции",
		"monitor_volume": "Мониторинг объемов",
		"enter_tooltip": "Для ввода значения нажмите Enter"
	}
};