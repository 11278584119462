module.exports = {
	"processor_accounts": {
		"title": "Аккаунты процессингов",
		"details": "Детали",
		"editing": "Редактирование",
		"creating": "Создание",
		"config": "Настройка",
		"config_account_data": "Настройка данных аккаунта",
		"config_external_data": "Внешние данные",
		"create_success": "Аккаунт успешно создан",
		"update_success": "Аккаунт успешно обновлен",
		"delete_success": "Аккаунт успешно удален",
		"validation_errors": "Ошибки валидации",
		"invalid_processor_name": "Неверный процессинг",
		"invalid_channel_name": "Канал не может быть пустым",
		"invalid_merchant_name": "Мерчант не может быть пустым",
		"invalid_title": "Название не может быть пустым",
		"invalid_account": "Неверный аккаунт",
		"required": "Пустой",
		"new": "Новый аккаунт"
	}
};