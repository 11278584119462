module.exports = {
	"monitor_volumes": {
		"title": "Monitor volumes",
		"name": "Name",
		"period_type": "Period",
		"type": "Type",
		"summ": "Summ",
		"channels": "Channels",
		"previous_period": "Previous period",
		"current_period": "Current period",
		"enabled": "Enabled",
		"new": "New",
		"created_success": "Monitor volume succesfully created",
		"updated_success": "Monitor volume succesfully updated",
		"amount": "Amount",
		"currency": "Currency",
		"coefficient": "Coefficient",
		"day": "Day",
		"week": "Week",
		"month": "Month",
		"quarter": "Quarter",
		"max_volume": "Max volume",
		"support_volume": "Support volume",
		"actions": "Actions",
		"edit": "Edit",
		"editing": "Editing",
		"comment": "Comment"
	}
};