export default () => ({
  url: "/manifest",
  method: "POST",
  mockedResponse: {
    bundle: {
      css: "/bundle-b95e5525ff6cc6a56cb0.css",
      js: "/bundle-b95e5525ff6cc6a56cb0.js",
    },
    translations: {
      js: "/translations-b95e5525ff6cc6a56cb0.js",
    },
    vendors: {
      css: "/vendors-b95e5525ff6cc6a56cb0.css",
      js: "/vendors-b95e5525ff6cc6a56cb0.js",
    },
  },
})
