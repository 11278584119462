import { LocalStorage } from "$utils/storage"

const ACCESS_TOKEN_STORAGE_KEY = "access_token"
const REFRESH_TOKEN_STORAGE_KEY = "refresh_token"

export const getAccessToken = () => LocalStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)
export const getRefreshToken = () => LocalStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)

export const setAccessToken = token => LocalStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token)
export const setRefreshToken = token => LocalStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, token)

export const clearTokens = () => {
  LocalStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY)
  LocalStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY)
}
