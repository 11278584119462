import loader from "$utils/assetsLoader"

const getFileExtension = filePath => filePath.split(".").pop()

export default class AssetsManager {
  _assets = []

  static make (...urls) {
    return new AssetsManager(...urls)
  }

  constructor (...urls) {
    this.setAssets(...urls)
  }

  get assets () {
    return this._assets
  }

  setAssets (...urls) {
    this._assets = urls.map(url => ({ url, type: getFileExtension(url) }))
  }

  load () {
    return Promise.all(this.assets.map(asset => loader[asset.type](asset.url)))
  }

  clear () {
    return this.assets.forEach(asset => loader.remove[asset.type](asset.url))
  }
}
