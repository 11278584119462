/* istanbul ignore file */

import { alertFailure } from "$utils/alerts"

const checkLocalStorage = () => {
  try {
    return "localStorage" in window && window.localStorage !== null
  } catch {
    alertFailure("Enable localStorage for the application to work correctly")
    return false
  }
}

class TempStorage {
  __data = {}

  setItem = (key, value) => { this.__data[key] = value }

  getItem = key => this.__data[key]

  removeItem = key => delete this.__data[key]
}

export const LocalStorage = checkLocalStorage() ? localStorage : new TempStorage()

class JSONStorage extends TempStorage {
  getItem = (key, defaultValue = {}) => {
    const value = LocalStorage.getItem(key)
    return this._parseValue(value, this._stringifyValue(defaultValue))
  }

  setItem = (key, value) => {
    const parsedValue = this._stringifyValue(value)
    LocalStorage.setItem(key, parsedValue)
  }

  removeItem = key => LocalStorage.removeItem(key)

  _stringifyValue = value => JSON.stringify(value) || "{}"

  _parseValue = (value, defaultValue = "{}") => {
    try {
      return JSON.parse(value || defaultValue)
    } catch {
      return null
    }
  }
}

export const JsonStorage = new JSONStorage()
