module.exports = {
	"processor_fees": {
		"title": "Комиссии процессингов",
		"processor": "Процессинг",
		"processor_channel": "Канал процессинга",
		"system": "ПС",
		"type": "Тип",
		"period": "Период",
		"fees": "Комиссии",
		"additional": "Доп. инфо",
		"additional_fees": "Дополнительные комиссии",
		"min_amount": "Мин. сумма",
		"max_amount": "Макс. сумма",
		"by_countries": "Комиссии по странам",
		"now": "настоящий момент",
		"and": "и"
	}
};