const isPresent = url => {
  const selector = /\.css$/.test(url) ? `link[href="${url}"]` : `script[src="${url}"]`
  return !!document.querySelector(selector)
}

const createElement = (name, attrs) => {
  const element = document.createElement(name)
  Object.entries(attrs).forEach(([key, value]) => (element[key] = value))
  return element
}

const js = url => new Promise((resolve, reject) => {
  /* istanbul ignore next */
  if (isPresent(url)) return resolve()

  const element = createElement("script", {
    type: "text/javascript",
    onerror: reject,
    onload: resolve,
    src: url,
  })
  document.body.appendChild(element)
})

const css = url => new Promise((resolve, reject) => {
  /* istanbul ignore next */
  if (isPresent(url)) return resolve()

  const element = createElement("link", {
    rel: "stylesheet",
    href: url,
    onerror: reject,
    onload: resolve,
  })
  document.head.appendChild(element)
})

const removeElement = element => {
  element.parentNode.removeChild(element)
}

const removeJs = url => {
  const scriptEl = document.querySelector(`script[src="${url}"`)
  removeElement(scriptEl)
}

const removeCss = url => {
  const styleEl = document.querySelector(`link[href="${url}"`)
  removeElement(styleEl)
}

const remove = {
  js: removeJs,
  css: removeCss,
}

export default {
  js,
  css,
  remove,
}
