module.exports = {
	"order_failure_codes": {
		"processor": "Processor",
		"code": "Failure code",
		"type": "Type",
		"subtype": "Subtype",
		"message": "Message",
		"actions": "Actions",
		"created_success": "Failure code is successfully created",
		"updated_success": "Failure code is successfully updated",
		"removed_success": "Failure code is successfully removed",
		"new_failure_code": "New failure code"
	}
};