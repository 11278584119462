module.exports = {
	"main": {
		"ab_tests": {
			"title": "АБ-тесты",
			"key": "Ключ эксперимента",
			"variations": "Список вариаций",
			"platforms": "Список платформ",
			"created_at": "Дата добавления",
			"updated_at": "Дата редактирования"
		},
		"payout_timings": {
			"title": "Тайминги выплат",
			"project": "Проект",
			"jurisdictions": "Юрисдикции",
			"deposit_status": "Статус игрока",
			"danger_status": "Статус опасности",
			"interval": "Интервал, минуты"
		},
		"balance_recommendations": {
			"title": "Рекомендации балансов",
			"name": "Баланс",
			"amount": "Рекомендуемая сумма"
		},
		"group_title": "Основное",
		"courses": {
			"title": "Курсы валют",
			"course_in": "Курс в",
			"course_for": "Курс за"
		},
		"withdraw_queue": {
			"title": "Очередь заявок на выплату",
			"player": "Игрок",
			"balance": "Баланс",
			"unprocessed_orders": "Необработанные заявки",
			"orders_have_comments": "Есть комментарии к заявкам",
			"verification": "Верификация"
		},
		"turnover": {
			"title": "Оборот игрока"
		},
		"purses": {
			"title": "Кошельки",
			"processor": "Процессинг",
			"system": "Система",
			"account": "Аккаунт",
			"bank": "Банк",
			"last_deposit_at": "Дата последнего успешного депозита",
			"last_withdraw_at": "Дата последней успешной выплаты",
			"expires_at": "Дата истечения срока действия",
			"enabled": "Включен"
		},
		"hung_withdraws": {
			"title": "Заявки: зависшие выплаты",
			"short_title": "Зависшие выплаты"
		},
		"processor_fees": {
			"title": "Комиссии процессингов"
		},
		"logs": {
			"title": "Пользовательские логи",
			"short_title": "Логи"
		},
		"csp": {
			"title": "Правила CSP"
		},
		"comments": {
			"empty": "Нет комментариев",
			"type": {
				"support": "Суппорт",
				"system": "Система",
				"payout_bot": "Робот автовыплат"
			}
		},
		"banks": {
			"title": "Банки",
			"bank": "Банк",
			"aliases": "Псевдонимы",
			"branding_id": "ID брэнда",
			"actions": "Действия"
		},
		"order_failure_codes": {
			"title": "Коды отказа заявок"
		},
		"custom_rates": {
			"title": "Кастомные курсы"
		}
	}
};